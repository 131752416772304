<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Créer une formation
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiSchool }}
        </v-icon>
      </v-card-title>
      <v-form>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newFormation.nom"
                label="Nom de la formation"
                outlined
                dense
                placeholder="Nom de la formation"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="newFormation.prix"
                label="Prix de la formation"
                type="number"
                outlined
                dense
                suffix="€"
                placeholder="Prix de la formation"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="newFormation.duree"
                label="Durée de la formation"
                outlined
                suffix="heures"
                dense
                type="number"
                placeholder="Durée de la formation"
                :hint="`${Number(newFormation.duree)} heures dont ${Number(visio)} heures de visio`"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="newFormation.tuteur"
                :items="tuteurs"
                item-text="nom"
                item-value="id"
                label="Tuteur"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="newFormation.certification"
                :items="certifications"
                item-text="nom"
                item-value="id"
                label="Certification"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-row class="d-flex">
                <v-col>
                  <v-checkbox
                    v-model="visioCheck"
                    label="Visio"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-text-field
                    v-if="visioCheck"
                    v-model="visio"
                    type="number"
                    :max="newFormation.duree"
                    label="Durée du visio"
                    outlined
                    dense
                    suffix="heure(s)"
                    placeholder="Durée visio"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            type="reset"
            outlined
            class="mx-2"
            @click="reset"
          >
            Réinitialiser
          </v-btn>
          <v-btn
            color="primary"
            @click="createFormation"
          >
            Créer
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>

import { mdiSchool } from '@mdi/js'

export default {
  components: {
  },
  data() {
    return {
      icons: {
        mdiSchool,
      },
      visioCheck: false,
      visio: null,
      newFormation: {
        nom: '',
        duree: '',
        prix: 0,
        visio: null,
        tuteur: [0],
      },
      tuteurs: [],
      certifications: [],
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchTuteurs(), this.fetchCertifs()])
        .then(values => {
          [this.tuteurs, this.certifications] = values
        })
        .catch(err => console.log(err))
        .finally(
          this.loading = false,
        )
    },
    async fetchTuteurs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/tuteurs`)

      return response.data
    },
    async fetchCertifs() {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/certifications`)

      return response.data
    },
    reset() {
      this.newFormation.nom = ''
      this.newFormation.duree = ''
    },
    createFormation() {
      if (this.visioCheck) {
        this.newFormation.visio = this.visio
      } else {
        this.newFormation.visio = null
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/formations/`, this.newFormation)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Formation crée avec succès',
            value: true,
          })
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création de la formation',
            value: true,
          })
        })
    },
  },
}
</script>

<style>

</style>
